import { API_URL } from './waitlistApi';

export const marketApi = {
  async getCurrentState() {
    try {
      const response = await fetch(`${API_URL}/market/current-state`);
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to get market state');
      }
      
      return data;
    } catch (error) {
      throw error;
    }
  },

  async getPriceHistory(params = {}) {
    try {
      const queryParams = new URLSearchParams(params).toString();
      const response = await fetch(`${API_URL}/market/price-history?${queryParams}`);
      
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to get price history');
      }
      
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  },

  async getLeaderboard() {
    try {
      const response = await fetch(`${API_URL}/pool/leaderboard`);
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to get leaderboard');
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }
};