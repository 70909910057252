import React from 'react';
import Avatar from '../common/Avatar';

const UserProfile = ({ user }) => {
  return (
    <div className="border-b border-gray-200">
      {/* User profile content */}
    </div>
  );
};

export default UserProfile;