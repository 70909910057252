import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Landing from './pages/Landing';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Post from './pages/Post';
import Messages from './pages/Messages';
import Notifications from './pages/Notifications';
import Explore from './pages/Explore';
import VerifyEmail from './pages/VerifyEmail';
import Market from './pages/Market';
import ControlMetaverse from './pages/ControlMetaverse';

// Componente para rutas protegidas
const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  
  if (!user) {
    return <Navigate to="/" replace />;
  }
  
  return children;
};

// Componente para la ruta de landing
const PublicRoute = ({ children }) => {
  const { user } = useAuth();
  
  if (user) {
    return <Navigate to="/home" replace />;
  }
  
  return children;
};

// Componente para rutas semi-públicas (accesibles con o sin autenticación)
const SemiPublicRoute = ({ children }) => {
  return children;
};

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <div className="min-h-screen bg-white dark:bg-dark-50 dark:text-white transition-colors duration-200">
          <Router>
            <Routes>
              {/* Ruta pública */}
              <Route 
                path="/" 
                element={
                  <PublicRoute>
                    <Landing />
                  </PublicRoute>
                } 
              />

              {/* Rutas semi-públicas */}
              <Route
                path="/market"
                element={
                  <SemiPublicRoute>
                    <Market />
                  </SemiPublicRoute>
                }
              />
              <Route
                path="/control-metaverse"
                element={
                  <SemiPublicRoute>
                    <ControlMetaverse />
                  </SemiPublicRoute>
                }
              />

              {/* Ruta de verificación de email */}
              <Route 
                path="/verify-email/:token" 
                element={<VerifyEmail />}
              />

              {/* Rutas protegidas */}
              <Route
                path="/home"
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/post/:id"
                element={
                  <ProtectedRoute>
                    <Post />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/messages"
                element={
                  <ProtectedRoute>
                    <Messages />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notifications"
                element={
                  <ProtectedRoute>
                    <Notifications />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/explore"
                element={
                  <ProtectedRoute>
                    <Explore />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Router>
        </div>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;