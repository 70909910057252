import React from 'react';
import Sidebar from './Sidebar';
import RightBar from './RightBar';

const MainLayout = ({ children }) => {
  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <main className="flex-1 ml-64 mr-80">{children}</main>
      <RightBar />
    </div>
  );
};

export default MainLayout;