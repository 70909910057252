import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { waitlistApi } from '../utils/waitlistApi';
import { auth } from '../utils/firebase';
import { 
  GoogleAuthProvider, 
  signInWithPopup,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';

const TimeBlock = ({ value, label }) => (
  <div className="flex flex-col items-center">
    <div className="text-4xl font-bold mb-1">{value.toString().padStart(2, '0')}</div>
    <div className="text-xs tracking-wider">{label}</div>
  </div>
);

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0, hours: 0, minutes: 0, seconds: 0
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const targetDate = new Date('2025-01-02T00:00:00');
      const now = new Date();
      const difference = targetDate - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    calculateTimeLeft();
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="text-center text-white">
      <div className="flex justify-center space-x-6">
        {Object.entries(timeLeft).map(([key, value]) => (
          <TimeBlock key={key} value={value} label={key.toUpperCase()} />
        ))}
      </div>
    </div>
  );
};

const ShareSection = ({ referralCode }) => {
  const shareUrl = `${window.location.origin}?ref=${referralCode}`;
  const shareMessage = "Join DirectiveAI Metaverse and discover a new way to see the world!";
  const hashTags = ["DirectiveAI", "Metaverse", "Future"];

  const shareLinks = {
    x: `https://x.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareMessage)}&hashtags=${hashTags.join(',')}`,
    instagram: `https://instagram.com/share?url=${encodeURIComponent(shareUrl)}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareMessage)}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}&summary=${encodeURIComponent(shareMessage)}`,
    whatsapp: `https://wa.me/?text=${encodeURIComponent(`${shareMessage}\n${shareUrl}`)}`
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      alert('Link copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const getSocialButtonStyle = (platform) => {
    const styles = {
      x: 'text-black hover:bg-gray-50',
      instagram: 'text-pink-600 hover:bg-pink-50',
      facebook: 'text-blue-600 hover:bg-blue-50',
      linkedin: 'text-blue-700 hover:bg-blue-50',
      whatsapp: 'text-green-500 hover:bg-green-50'
    };
    return styles[platform] || '';
  };

  const getSocialIcon = (platform) => {
    const icons = {
      x: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
           <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
         </svg>,
      instagram: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                </svg>,
      facebook: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                 <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
               </svg>,
      linkedin: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                 <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
               </svg>,
      whatsapp: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                 <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"/>
               </svg>
    };
    return icons[platform] || null;
  };

  return (
    <div className="space-y-4">
      <div className="text-center space-y-2">
        <p className="text-sm text-gray-600">Share with friends:</p>
        <div className="flex justify-center space-x-4">
          {Object.entries(shareLinks).map(([platform, url]) => (
            <a
              key={platform}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className={`p-2 rounded-full transition-colors ${getSocialButtonStyle(platform)}`}
            >
              {getSocialIcon(platform)}
            </a>
          ))}
          <button
            onClick={copyToClipboard}
            className="p-2 text-gray-600 hover:bg-gray-50 rounded-full transition-colors"
            title="Copy link"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

const WaitlistModal = ({ isOpen, onClose }) => {
  const [status, setStatus] = useState('initial');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [userData, setUserData] = useState(null);
  const [user, setUser] = useState(null);
  const [referralCode, setReferralCode] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('ref') || '';
  });
  
  const hasReferralFromUrl = Boolean(new URLSearchParams(window.location.search).get('ref'));

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleSocialLogin = async () => {
    try {
      setLoading(true);
      setError('');
      setStatus('initial');
      const authProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, authProvider);
      
      try {
        const response = await waitlistApi.join(
          result.user.email,
          result.user.displayName,
          referralCode
        );

        setStatus('pending');
        setUserData({
          email: result.user.email,
          name: result.user.displayName,
          referralCode: response.referralCode
        });

      } catch (error) {
        if (error.message.includes('already registered')) {
          setStatus('verified');
          setUserData({
            email: result.user.email,
            name: result.user.displayName,
            referralCode: error.referralCode || 'Your code was not found'
          });
        } else {
          setStatus('error');
          setError(error.message || 'Failed to process your request');
        }
      }

    } catch (error) {
      console.error('Social login error:', error);
      setStatus('error');
      setError(error.message || 'An error occurred during authentication');
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationComplete = async () => {
    setStatus('verified');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>
        
        {status === 'initial' && (
          <div className="w-full">
            <h2 className="text-2xl font-bold mb-4 text-center">
              Join the Waitlist
            </h2>
            
            {error && (
              <div className="mb-4 p-3 bg-red-50 text-red-500 rounded-md">
                {error}
              </div>
            )}

            <div className="mb-6">
              <input
                type="text"
                value={referralCode}
                onChange={(e) => !hasReferralFromUrl && setReferralCode(e.target.value)}
                placeholder="Have a referral code? Enter it here"
                className="w-full px-4 py-3 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={hasReferralFromUrl}
              />
            </div>

            <button
              onClick={handleSocialLogin}
              disabled={loading}
              className="flex items-center justify-center w-full px-4 py-3 text-gray-900 bg-white rounded-full border border-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z"
                />
              </svg>
              {loading ? 'Processing...' : 'Continue with Google'}
            </button>
          </div>
        )}

        {status === 'pending' && userData && (
          <div className="text-center">
            <div className="mb-6">
              <h3 className="text-xl font-bold text-blue-600 mb-2">
                Check Your Email
              </h3>
              <p className="text-gray-600">
                We've sent a verification link to {userData.email}.<br/>
                Please verify your email to complete registration.
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg mb-6">
              <p className="text-sm text-gray-600">
                Didn't receive the email? Check your spam folder or
                <button 
                  onClick={handleSocialLogin}
                  className="text-blue-600 hover:text-blue-700 ml-1"
                >
                  try again
                </button>
              </p>
            </div>

            <button
              onClick={() => {
                signOut(auth);
                onClose();
              }}
              className="w-full bg-gray-100 text-gray-700 py-2 rounded-md hover:bg-gray-200"
            >
              Close
            </button>
          </div>
        )}

        {status === 'verified' && userData && (
          <div className="text-center">
            <div className="mb-6">
              <h3 className="text-xl font-bold text-green-600 mb-2">
                Welcome back!
              </h3>
              <p className="text-gray-600">
                Thank you for joining! Share your referral code with friends.
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg mb-6 space-y-4">
              <div className="space-y-2">
                <p className="text-sm text-gray-600">
                  The human with the most invitations wins a cash prize.
                </p>
              </div>

              <div className="bg-white p-4 rounded-md border border-gray-200">
                <p className="text-sm text-gray-600 mb-2">Your Referral Code:</p>
                <div className="flex items-center justify-center space-x-2">
                  <code className="font-mono text-lg bg-gray-50 px-4 py-2 rounded border">
                    {userData.referralCode}
                  </code>
                </div>
              </div>

              <ShareSection referralCode={userData.referralCode} />
            </div>

            <button
              onClick={() => {
                signOut(auth);
                onClose();
              }}
              className="w-full bg-gray-100 text-gray-700 py-2 rounded-md hover:bg-gray-200"
            >
              Close
            </button>
          </div>
        )}

        {status === 'error' && (
          <div className="text-center">
            <div className="mb-6">
              <h3 className="text-xl font-bold text-red-600 mb-2">
                Oops! Something went wrong
              </h3>
              <p className="text-gray-600">
                {error}
              </p>
            </div>

            <button
              onClick={() => {
                setStatus('initial');
                setError('');
              }}
              className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700"
            >
              Try Again
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const Landing = () => {
  const [showWaitlist, setShowWaitlist] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('ref')) {
      setShowWaitlist(true);
    }
  }, []);

  return (
    <div className="fixed inset-0 w-screen overflow-x-hidden bg-black">
      <div className="relative w-full min-h-screen">
        {/* Background */}
        <div className="absolute inset-0">
          <picture className="w-full h-full">
            <source
              media="(max-width: 767px)"
              srcSet="/assets/images/frame_1.png"
            />
            <source
              media="(min-width: 768px)"
              srcSet="/assets/images/frame_0.png"
            />
            <img
              src="/assets/images/frame_0.png"
              alt="DirectiveAI Metaverse Background"
              className="w-full h-full object-cover"
            />
          </picture>
          <div className="absolute inset-0 bg-black bg-opacity-0"></div>
        </div>

        <div className="relative z-10 flex flex-col min-h-screen w-full">
          <div className="flex-1 w-full px-4 sm:px-6 lg:px-8">
            <div className="pt-16 sm:pt-20 md:pt-24 w-full">
              <div className="w-full max-w-[90vw] md:max-w-2xl lg:max-w-4xl mx-auto md:mx-0 md:ml-auto lg:ml-32">
                <div className="text-center md:text-left">
                  {/* Título ajustado a 80px en desktop y 48px en móvil */}
                  <h1 className="text-[48px] leading-tight md:text-[80px] font-bold text-black break-words tracking-tight">
                    Omverse
                  </h1>
                  {/* Subtítulo ajustado a 28px en desktop y 21px en móvil */}
                  <p className="mt-4 sm:mt-6 text-[21px] md:text-[28px] text-black">
                    The DirectiveAI Metaverse.
                  </p>
                  {/* Contenedor de botones */}
                  <div className="mt-6 sm:mt-8 flex flex-col sm:flex-row items-center gap-4 sm:gap-6 justify-center md:justify-start">
                    {/* Botones ajustados a 171x44 en desktop y 138x36 en móvil */}
                    <button
                      onClick={() => navigate('/market')}
                      className="w-[138px] h-[36px] md:w-[171px] md:h-[44px] bg-transparent text-black border border-black font-medium rounded-full hover:bg-black hover:text-white transition-all duration-300"
                    >
                      Market
                    </button>
                    <button
                      onClick={() => setShowWaitlist(true)}
                      className="w-[138px] h-[36px] md:w-[171px] md:h-[44px] bg-black text-white border border-black font-medium rounded-full hover:bg-transparent hover:text-black transition-all duration-300"
                    >
                      Join Waitlist
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full pb-6 sm:pb-8">
            <Countdown />
            <div className="w-full mt-4 text-white/80 text-xs sm:text-sm text-center px-4">
              powered by DirectiveAI Inc. 
            </div>
          </div>
        </div>
      </div>

      <WaitlistModal
        isOpen={showWaitlist}
        onClose={() => {
          setShowWaitlist(false);
          const url = new URL(window.location.href);
          url.searchParams.delete('ref');
          window.history.replaceState({}, '', url);
        }}
      />
    </div>
  );
};

export default Landing;