// frontend/src/utils/waitlistApi.js

export const API_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:5000/api'
  : 'https://b8k8d0pj4l.execute-api.us-east-2.amazonaws.com/prod/api';

export const waitlistApi = {
  async join(email, name, referralCode) {
    try {
      const response = await fetch(`${API_URL}/waitlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, name, referralCode }),
      });
      
      const responseText = await response.text();
      let data;
      
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        throw new Error('Invalid JSON response from server');
      }
      
      if (!response.ok) {
        if (response.status === 409) {
          throw Object.assign(new Error(data.error), {
            referralCode: data.referralCode
          });
        }
        throw new Error(data.error || 'Failed to join waitlist');
      }
      
      return data;
    } catch (error) {
      console.error('Error joining waitlist:', error);
      throw error;
    }
  },

  async verifyEmail(token) {
    try {
      const response = await fetch(`${API_URL}/waitlist/verify/${token}`);
      const responseText = await response.text();
      
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        throw new Error('Invalid JSON response from server');
      }

      if (!response.ok) {
        throw new Error(data.error || 'Failed to verify email');
      }

      return data;
    } catch (error) {
      console.error('Error verifying email:', error);
      throw error;
    }
  },

  async getReferralStats(email) {
    try {
      const response = await fetch(`${API_URL}/waitlist/referral-stats/${email}`);
      const responseText = await response.text();
      
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        throw new Error('Invalid JSON response from server');
      }

      if (!response.ok) {
        throw new Error(data.error || 'Failed to get referral stats');
      }

      return data;
    } catch (error) {
      console.error('Error getting referral stats:', error);
      throw error;
    }
  },

  async submitAppeal(email, reason, evidence) {
    try {
      const response = await fetch(`${API_URL}/waitlist/appeal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, reason, evidence }),
      });
      
      const responseText = await response.text();
      
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        throw new Error('Invalid JSON response from server');
      }

      if (!response.ok) {
        throw new Error(data.error || 'Failed to submit appeal');
      }

      return data;
    } catch (error) {
      console.error('Error submitting appeal:', error);
      throw error;
    }
  }
};