// src/utils/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyC6xB5oP6DwsT1z67xFzS1KEGYSR2cXShg",
  authDomain: "omverseai.firebaseapp.com",
  projectId: "omverseai",
  storageBucket: "omverseai.firebasestorage.app",
  messagingSenderId: "1079523445620",
  appId: "1:1079523445620:web:f38c390880a603384eba21"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);