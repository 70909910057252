import React from 'react';
import MainLayout from '../components/layout/MainLayout';

const Notifications = () => {
  return (
    <MainLayout>
      <div className="p-4">Notifications coming soon</div>
    </MainLayout>
  );
};

export default Notifications;