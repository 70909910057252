// src/components/post/PostComposer.jsx
import React, { useState } from 'react';
import { Image, Smile, Calendar, MapPin } from 'lucide-react';

const PostComposer = ({ onPost }) => {
  const [content, setContent] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (content.trim()) {
      onPost?.(content);
      setContent('');
    }
  };

  return (
    <div className="border-b border-gray-200 p-4">
      <div className="flex space-x-4">
        {/* User Avatar */}
        <img
          src="/api/placeholder/48/48"
          alt="Your avatar"
          className="w-12 h-12 rounded-full"
        />

        {/* Composer Form */}
        <form onSubmit={handleSubmit} className="flex-1">
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="¿Qué está pasando?"
            className="w-full text-xl placeholder-gray-600 outline-none resize-none mb-4"
            rows={3}
          />

          {/* Action Buttons */}
          <div className="flex items-center justify-between pt-2 border-t border-gray-200">
            <div className="flex space-x-2">
              <button type="button" className="p-2 hover:bg-blue-50 rounded-full text-blue-500">
                <Image size={20} />
              </button>
              <button type="button" className="p-2 hover:bg-blue-50 rounded-full text-blue-500">
                <Smile size={20} />
              </button>
              <button type="button" className="p-2 hover:bg-blue-50 rounded-full text-blue-500">
                <Calendar size={20} />
              </button>
              <button type="button" className="p-2 hover:bg-blue-50 rounded-full text-blue-500">
                <MapPin size={20} />
              </button>
            </div>

            <button
              type="submit"
              disabled={!content.trim()}
              className={`px-4 py-2 rounded-full font-bold ${
                content.trim()
                  ? 'bg-blue-500 text-white hover:bg-blue-600'
                  : 'bg-blue-300 text-white cursor-not-allowed'
              }`}
            >
              Postear
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostComposer;