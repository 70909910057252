import React from 'react';
import MainLayout from '../components/layout/MainLayout';

const Explore = () => {
  return (
    <MainLayout>
      <div className="p-4">Explore coming soon</div>
    </MainLayout>
  );
};

export default Explore;