// src/components/post/PostCard.jsx
import React from 'react';
import { Heart, MessageCircle, Repeat2, Share } from 'lucide-react';
import { Link } from 'react-router-dom';

const PostCard = ({ post }) => {
  const { author, content, createdAt, likes, replies, reposts, views } = post;

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1)}M`;
    }
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}K`;
    }
    return num.toString();
  };

  return (
    <article className="border-b border-gray-200 p-4 hover:bg-gray-50 transition-colors cursor-pointer">
      <div className="flex space-x-4">
        {/* Avatar */}
        <Link to={`/profile/${author.id}`}>
          <img
            src={author.avatar}
            alt={author.name}
            className="w-10 h-10 rounded-full"
          />
        </Link>

        {/* Content */}
        <div className="flex-1">
          {/* Author info */}
          <div className="flex items-center space-x-2">
            <Link to={`/profile/${author.id}`} className="font-bold hover:underline">
              {author.name}
            </Link>
            <span className="text-gray-500">{author.username}</span>
            <span className="text-gray-500">·</span>
            <span className="text-gray-500">
              {new Date(createdAt).toLocaleDateString()}
            </span>
          </div>

          {/* Post text */}
          <p className="mt-2 text-gray-900">{content}</p>

          {/* Actions */}
          <div className="flex justify-between mt-4 max-w-md text-gray-500">
            <button className="flex items-center space-x-2 group">
              <div className="p-2 rounded-full group-hover:bg-blue-50 group-hover:text-blue-500">
                <MessageCircle size={18} />
              </div>
              <span>{formatNumber(replies)}</span>
            </button>

            <button className="flex items-center space-x-2 group">
              <div className="p-2 rounded-full group-hover:bg-green-50 group-hover:text-green-500">
                <Repeat2 size={18} />
              </div>
              <span>{formatNumber(reposts)}</span>
            </button>

            <button className="flex items-center space-x-2 group">
              <div className="p-2 rounded-full group-hover:bg-red-50 group-hover:text-red-500">
                <Heart size={18} />
              </div>
              <span>{formatNumber(likes)}</span>
            </button>

            <button className="flex items-center space-x-2 group">
              <div className="p-2 rounded-full group-hover:bg-blue-50 group-hover:text-blue-500">
                <Share size={18} />
              </div>
              <span>{formatNumber(views)}</span>
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default PostCard;