import React from 'react';

const RightBar = () => {
  return (
    <aside className="hidden lg:block fixed right-0 h-screen w-80 border-l border-gray-200">
      {/* RightBar content */}
    </aside>
  );
};

export default RightBar;