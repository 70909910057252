import React from 'react';
import MainLayout from '../components/layout/MainLayout';
import UserProfile from '../components/user/UserProfile';

const Profile = () => {
  return (
    <MainLayout>
      <UserProfile />
    </MainLayout>
  );
};

export default Profile;