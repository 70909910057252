// src/pages/Home.jsx
import React, { useState, useEffect } from 'react';
import MainLayout from '../components/layout/MainLayout';
import Header from '../components/layout/Header';
import PostComposer from '../components/post/PostComposer';
import PostCard from '../components/post/PostCard';
import api from '../utils/api';

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPosts();
  }, []);

  const loadPosts = async () => {
    try {
      const fetchedPosts = await api.getPosts();
      setPosts(fetchedPosts);
    } catch (error) {
      console.error('Error loading posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNewPost = async (content) => {
    try {
      const newPost = await api.createPost(content);
      setPosts([newPost, ...posts]);
    } catch (error) {
      console.error('Error creating post:', error);
    }
  };

  return (
    <div className="flex min-h-screen bg-white">
      <div className="flex-1 max-w-2xl mx-auto border-x border-gray-200">
        <Header title="Inicio" />
        <PostComposer onPost={handleNewPost} />
        
        {loading ? (
          <div className="flex justify-center p-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
          </div>
        ) : (
          <div>
            {posts.map((post) => (
              <PostCard key={post.id} post={post} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;