import React, { useState, useEffect } from 'react';
import { marketApi } from '../utils/marketApi';
import { TrendingUp, Info, X } from 'lucide-react';

const BubbleMembers = ({ participants }) => {
  const [bubbles, setBubbles] = useState([]);

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const generatePosition = (index, total) => {
    const angle = (index / total) * 2 * Math.PI;
    const radius = 150;
    const centerOffset = { x: 20, y: 20 };
    
    return {
      x: Math.cos(angle) * radius + centerOffset.x,
      y: Math.sin(angle) * radius + centerOffset.y,
      initials: getInitials(participants[index].name || 'User Name'),
      color: `rgba(0, 0, 0, ${0.8 - (index * 0.1)})`,
    };
  };

  useEffect(() => {
    const initialBubbles = participants.slice(0, 8).map((_, index) => 
      generatePosition(index, Math.min(participants.length, 8))
    );
    setBubbles(initialBubbles);

    const interval = setInterval(() => {
      setBubbles(prevBubbles => 
        prevBubbles.map(bubble => ({
          ...bubble,
          x: bubble.x + (Math.random() * 0.3 - 0.15),
          y: bubble.y + (Math.random() * 0.3 - 0.15),
        }))
      );
    }, 60);

    return () => clearInterval(interval);
  }, [participants]);

  return (
    <div className="relative w-full h-72 bg-white rounded-3xl overflow-hidden">
      <div className="absolute left-1/2 top-1/2 w-0 h-0" />
      
      {bubbles.map((bubble, index) => (
        <div
          key={index}
          className="absolute transform -translate-x-1/2 -translate-y-1/2 transition-all duration-700"
          style={{
            left: `calc(50% + ${bubble.x}px)`,
            top: `calc(50% + ${bubble.y}px)`,
          }}
        >
          <div
            className="w-14 h-14 rounded-full flex items-center justify-center text-white font-medium shadow-lg transition-transform hover:scale-105"
            style={{
              backgroundColor: bubble.color,
            }}
          >
            {bubble.initials}
          </div>
        </div>
      ))}
    </div>
  );
};

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-black/40 backdrop-blur-xl">
      <div className="flex min-h-full items-center justify-center p-4">
        <div className="w-full max-w-lg bg-white/95 rounded-3xl shadow-2xl">
          <div className="p-8">
            <div className="flex items-center justify-between mb-8">
              <h3 className="text-2xl font-semibold text-gray-900">{title}</h3>
              <button
                onClick={onClose}
                className="rounded-full p-2 hover:bg-gray-100 transition-colors"
              >
                <X className="w-6 h-6 text-gray-400" />
              </button>
            </div>
            <div className="text-gray-600 leading-relaxed">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AnimatedNumber = ({ value, prefix = '', suffix = '', duration = 1500, decimals = 2 }) => {
  const [displayValue, setDisplayValue] = useState(value);
  const steps = 40;

  useEffect(() => {
    let startValue = displayValue;
    const endValue = value;
    const increment = (endValue - startValue) / steps;
    const stepDuration = duration / steps;
    let currentStep = 0;

    const timer = setInterval(() => {
      if (currentStep < steps) {
        setDisplayValue(prevValue => {
          const newValue = prevValue + increment;
          return newValue + (Math.random() - 0.5) * Math.abs(increment) * 0.1;
        });
        currentStep++;
      } else {
        setDisplayValue(endValue);
        clearInterval(timer);
      }
    }, stepDuration);

    return () => clearInterval(timer);
  }, [value, duration]);

  return (
    <span className="font-semibold tracking-tight">
      {prefix}{displayValue.toFixed(decimals)}{suffix}
    </span>
  );
};

const Market = () => {
  const [marketState, setMarketState] = useState({
    totalAmount: 0,
    totalParticipants: 0
  });
  const [error, setError] = useState(null);
  const [leaderboard, setLeaderboard] = useState([]);
  const [messageIndex, setMessageIndex] = useState(0);
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [showMembersModal, setShowMembersModal] = useState(false);

  const marketMessages = [
    {
      value: "Refer & Earn",
      subtitle: "Share the experience. Build your network."
    },
    {
      value: "Time is Limited",
      subtitle: "Don't miss this unique opportunity"
    },
    {
      value: "Grow Together",
      subtitle: "Every referral counts towards your success"
    },
    {
      value: "Lead & Win",
      subtitle: "Be the one to claim the grand prize"
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % marketMessages.length);
    }, 6000);
    return () => clearInterval(interval);
  }, [marketMessages.length]);

  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        const [state, board] = await Promise.all([
          marketApi.getCurrentState(),
          marketApi.getLeaderboard()
        ]);

        setMarketState({
          totalAmount: Number(state.poolValue || 0),
          totalParticipants: board.leaderboard.length || 0
        });

        setLeaderboard(board.leaderboard || []);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchMarketData();
    const interval = setInterval(fetchMarketData, 30000);
    return () => clearInterval(interval);
  }, []);

  if (error) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="text-red-600 bg-red-50 px-8 py-6 rounded-2xl">
          Error: {error}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-6 py-16">
        <div className="space-y-8">
          <div className="bg-white rounded-3xl shadow-sm border border-gray-100 overflow-hidden">
            <div className="p-12 text-center">
              <div className="flex items-center justify-center gap-3 mb-8">
                <h1 className="text-3xl font-semibold text-gray-900">Total Metaverse Reward</h1>
                <button
                  onClick={() => setShowRewardModal(true)}
                  className="text-gray-400 hover:text-gray-600 transition-colors"
                >
                  <Info className="w-6 h-6" />
                </button>
              </div>
              
              <div className="inline-block mb-6">
                <div className="text-6xl font-semibold text-gray-900 mb-4 tracking-tight">
                  <AnimatedNumber value={marketState.totalAmount} prefix="$" />
                </div>
                <div className="space-y-2">
                  <p className="text-gray-500">
                    Live updates • USD
                  </p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-px bg-gray-100">
              <div className="bg-white p-8 text-center">
                <div className="flex items-center justify-center gap-2 mb-4">
                  <h2 className="text-xl font-medium text-gray-900">Community</h2>
                  <button
                    onClick={() => setShowMembersModal(true)}
                    className="text-gray-400 hover:text-gray-600 transition-colors"
                  >
                    <Info className="w-5 h-5" />
                  </button>
                </div>
                <div className="text-4xl font-semibold text-gray-900 mb-3">
                  <AnimatedNumber value={marketState.totalParticipants} decimals={0} />
                </div>
                <p className="text-gray-500">Active members</p>
              </div>

              <div className="bg-white p-8 text-center">
                <div className="text-4xl font-semibold text-gray-900 mb-3">
                  {marketMessages[messageIndex].value}
                </div>
                <p className="text-gray-500">
                  {marketMessages[messageIndex].subtitle}
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-3xl shadow-sm border border-gray-100 p-8">
            <h3 className="text-xl font-medium text-gray-900 mb-8 text-center">
              Featured Members
            </h3>
            <BubbleMembers participants={leaderboard} />
          </div>
        </div>
      </div>

      <Modal
        isOpen={showRewardModal}
        onClose={() => setShowRewardModal(false)}
        title="About the Prize Pool"
      >
        <div className="space-y-6">
          <p className="text-lg">
            The entire prize will be awarded to the participant with the highest number of successful referrals at the end of the competition.
          </p>
          <div className="space-y-4">
            <p className="text-lg font-medium">To increase your chances:</p>
            <ul className="space-y-3 text-gray-600">
              <li className="flex items-center gap-2">
                <span className="w-1 h-1 bg-gray-400 rounded-full"></span>
                Refer friends, family, and colleagues
              </li>
              <li className="flex items-center gap-2">
                <span className="w-1 h-1 bg-gray-400 rounded-full"></span>
                Each verified referral counts
              </li>
              <li className="flex items-center gap-2">
                <span className="w-1 h-1 bg-gray-400 rounded-full"></span>
                Build your network to maximize chances
              </li>
            </ul>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showMembersModal}
        onClose={() => setShowMembersModal(false)}
        title="About Our Community"
      >
        <div className="space-y-6">
          <p className="text-lg">
            Our community brings together referrers and participants in a unique ecosystem. While exact numbers remain private, every member contributes to our growing network.
          </p>
          <p className="text-lg">
            Whether you're here to refer others or seeking referrals yourself, you're part of an exclusive group working towards a common goal.
          </p>
          <p className="text-gray-500">
            Join the movement. Start referring today.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Market;