// src/utils/api.js
import { MOCK_POSTS, MOCK_USERS } from './mockData';

const api = {
  getPosts: async () => {
    // Simular un delay de red
    await new Promise(resolve => setTimeout(resolve, 500));
    return MOCK_POSTS;
  },
  
  getUser: async (id) => {
    await new Promise(resolve => setTimeout(resolve, 500));
    return MOCK_USERS.find(user => user.id === id);
  },
  
  createPost: async (content) => {
    await new Promise(resolve => setTimeout(resolve, 500));
    return {
      id: Date.now().toString(),
      content,
      author: MOCK_USERS[0], // Usuario actual simulado
      createdAt: new Date(),
      likes: 0,
      reposts: 0,
      replies: 0,
      views: 0,
      isLiked: false,
      isReposted: false
    };
  }
};

export default api;