// src/components/common/ThemeSelector.jsx
import React from 'react';
import { Sun, Moon, Monitor } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

const ThemeSelector = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <div className="flex items-center space-x-2 p-3 rounded-lg bg-gray-100 dark:bg-dark-200">
      <button
        onClick={() => toggleTheme('light')}
        className={`p-2 rounded-full ${
          theme === 'light'
            ? 'bg-blue-500 text-white'
            : 'hover:bg-gray-200 dark:hover:bg-dark-300'
        }`}
        title="Modo claro"
      >
        <Sun size={20} />
      </button>
      <button
        onClick={() => toggleTheme('dark')}
        className={`p-2 rounded-full ${
          theme === 'dark'
            ? 'bg-blue-500 text-white'
            : 'hover:bg-gray-200 dark:hover:bg-dark-300'
        }`}
        title="Modo oscuro"
      >
        <Moon size={20} />
      </button>
      <button
        onClick={() => toggleTheme('system')}
        className={`p-2 rounded-full ${
          theme === 'system'
            ? 'bg-blue-500 text-white'
            : 'hover:bg-gray-200 dark:hover:bg-dark-300'
        }`}
        title="Usar tema del sistema"
      >
        <Monitor size={20} />
      </button>
    </div>
  );
};

export default ThemeSelector;