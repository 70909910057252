// src/utils/mockData.js
export const MOCK_USERS = [
    {
      id: '1',
      name: 'John Doe',
      username: '@johndoe',
      avatar: '/api/placeholder/50/50',
      bio: 'Software Developer | Tech Enthusiast',
      followers: 1234,
      following: 567,
      verified: true
    },
    {
      id: '2',
      name: 'Jane Smith',
      username: '@janesmith',
      avatar: '/api/placeholder/50/50',
      bio: 'Digital Artist | Creator',
      followers: 8765,
      following: 432,
      verified: false
    },
  ];
  
  export const MOCK_POSTS = [
    {
      id: '1',
      content: 'Just launched my new project! 🚀 #coding #webdev',
      author: MOCK_USERS[0],
      createdAt: new Date('2024-01-15T12:00:00'),
      likes: 42,
      reposts: 12,
      replies: 8,
      views: 1234,
      isLiked: false,
      isReposted: false
    },
    {
      id: '2',
      content: 'Working on some new designs for the portfolio. Stay tuned! 🎨',
      author: MOCK_USERS[1],
      createdAt: new Date('2024-01-14T15:30:00'),
      likes: 89,
      reposts: 24,
      replies: 15,
      views: 2345,
      isLiked: true,
      isReposted: false
    },
  ];