import React from 'react';
import MainLayout from '../components/layout/MainLayout';
import PostCard from '../components/post/PostCard';

const Post = () => {
  return (
    <MainLayout>
      <PostCard />
    </MainLayout>
  );
};

export default Post;