import React from 'react';

const Header = ({ title }) => {
  return (
    <header className="sticky top-0 bg-white/80 backdrop-blur-sm border-b border-gray-200">
      <h1 className="text-xl font-bold p-4">{title}</h1>
    </header>
  );
};

export default Header;