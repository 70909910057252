import React from 'react';

const DirectiveLogo = ({ className = '', size = 16 }) => {
  const aspectRatio = 1;
  const height = size * aspectRatio;

  return (
    <svg 
      width={size} 
      height={height}
      viewBox="0 0 405 860"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.954 563.751C241.234 522.95 229.977 478.105 229.977 430.987C229.977 383.885 241.227 338.933 254.94 297.96C261.701 277.762 269.06 258.53 275.966 240.484C311.551 147.491 335.082 86 202.494 86C69.9143 86 93.4499 147.483 129.044 240.466C135.954 258.518 143.319 277.757 150.084 297.963C163.801 338.935 175.053 383.886 175.053 430.987C175.053 478.104 163.793 522.948 150.07 563.748C143.268 583.97 135.861 603.198 128.92 621.218C93.3956 713.441 70.068 774 202.494 774C334.928 774 311.605 713.433 276.089 621.2C269.153 603.186 261.751 583.965 254.954 563.751ZM22.7224 68.7356C64.9826 9.28678 144.514 0 202.494 0C260.478 0 339.999 9.28837 382.264 68.7251C401.978 96.4488 405.701 125.373 404.901 147.68C404.111 169.713 398.681 191.171 393.066 209.219C387.355 227.576 379.931 247.01 373.08 264.919L372.438 266.597C365.522 284.672 358.796 302.249 352.643 320.549C339.966 358.25 331.227 394.866 331.227 430.987C331.227 467.089 339.959 503.561 352.61 541.018C358.794 559.328 365.561 576.902 372.52 594.977L373.021 596.278C379.863 614.049 387.297 633.37 393.015 651.605C398.634 669.526 404.104 690.92 404.9 712.92C405.707 735.228 401.944 764.203 382.016 791.93C339.558 851.006 260.054 860 202.494 860C144.938 860 65.423 851.008 22.9702 791.92C3.0462 764.188 -0.71056 735.212 0.101256 712.905C0.901894 690.906 6.37478 669.514 11.9969 651.594C17.7176 633.36 25.1543 614.04 31.9984 596.27L32.4917 594.989C39.4561 576.907 46.2272 559.328 52.4154 541.012C65.0697 503.556 73.8023 467.086 73.8023 430.987C73.8023 394.868 65.0619 358.255 52.3822 320.556C46.225 302.249 39.4953 284.667 32.5747 266.586L31.9399 264.928C25.0862 247.02 17.6594 227.586 11.9458 209.23C6.3286 191.183 0.894725 169.727 0.100363 147.695C-0.703849 125.389 3.01178 96.4632 22.7224 68.7356Z"
        fill="black"
      />
    </svg>
  );
};

export default DirectiveLogo;