import React from 'react';
import MainLayout from '../components/layout/MainLayout';

const Messages = () => {
  return (
    <MainLayout>
      <div className="p-4">Messages coming soon</div>
    </MainLayout>
  );
};

export default Messages;