// src/components/layout/Sidebar.jsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, Search, Bell, Mail, User, MoreHorizontal } from 'lucide-react';
import ThemeSelector from '../common/ThemeSelector';

const Sidebar = () => {
  const location = useLocation();
  
  const menuItems = [
    { icon: Home, text: 'Inicio', path: '/' },
    { icon: Search, text: 'Explorar', path: '/explore' },
    { icon: Bell, text: 'Notificaciones', path: '/notifications' },
    { icon: Mail, text: 'Mensajes', path: '/messages' },
    { icon: User, text: 'Perfil', path: '/profile' }
  ];

  return (
    <div className="fixed h-screen w-72 border-r border-gray-200 dark:border-dark-200 p-4 bg-white dark:bg-dark-50">
      {/* Logo */}
      <div className="mb-4">
        <Link to="/">
          <span className="text-3xl font-bold">X</span>
        </Link>
      </div>

      {/* Navigation Menu */}
      <nav className="space-y-2">
        {menuItems.map((item) => {
          const Icon = item.icon;
          const isActive = location.pathname === item.path;
          
          return (
            <Link
              key={item.path}
              to={item.path}
              className={`flex items-center space-x-4 p-3 rounded-full hover:bg-gray-100 dark:hover:bg-dark-200 transition-colors ${
                isActive ? 'font-bold' : ''
              }`}
            >
              <Icon size={24} />
              <span className="text-xl">{item.text}</span>
            </Link>
          );
        })}
      </nav>

      {/* Post Button */}
      <button className="w-full bg-blue-500 text-white rounded-full py-3 px-8 font-bold mt-4 hover:bg-blue-600 transition-colors">
        Postear
      </button>

      {/* Theme Selector */}
      <div className="mt-4">
        <ThemeSelector />
      </div>

      {/* User Profile Preview */}
      <div className="absolute bottom-4 w-64">
        <div className="flex items-center p-3 rounded-full hover:bg-gray-100 dark:hover:bg-dark-200 cursor-pointer">
          <img
            src="/api/placeholder/40/40"
            alt="Profile"
            className="w-10 h-10 rounded-full"
          />
          <div className="ml-3">
            <p className="font-bold">John Doe</p>
            <p className="text-gray-500 dark:text-gray-400">@johndoe</p>
          </div>
          <MoreHorizontal className="ml-auto" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;