import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { waitlistApi } from '../utils/waitlistApi';
import DirectiveLogo from '../components/common/DirectiveLogo';

const ShareSection = ({ referralCode }) => {
  const shareUrl = `${window.location.origin}?ref=${referralCode}`;
  const shareMessage = "Join DirectiveAI Metaverse and discover a new way to see the world!";
  const hashTags = ["DirectiveAI", "Metaverse", "Future"];

  const shareLinks = {
    x: `https://x.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareMessage)}&hashtags=${hashTags.join(',')}`,
    instagram: `https://instagram.com/share?url=${encodeURIComponent(shareUrl)}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareMessage)}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}&summary=${encodeURIComponent(shareMessage)}`,
    whatsapp: `https://wa.me/?text=${encodeURIComponent(`${shareMessage}\n${shareUrl}`)}`
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      alert('Link copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const getSocialButtonStyle = (platform) => {
    const styles = {
      x: 'text-black hover:bg-gray-50',
      instagram: 'text-pink-600 hover:bg-pink-50',
      facebook: 'text-blue-600 hover:bg-blue-50',
      linkedin: 'text-blue-700 hover:bg-blue-50',
      whatsapp: 'text-green-500 hover:bg-green-50'
    };
    return styles[platform] || '';
  };

  const getSocialIcon = (platform) => {
    const icons = {
      x: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
           <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
         </svg>,
      instagram: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                </svg>,
      facebook: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                 <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
               </svg>,
      linkedin: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                 <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
               </svg>,
      whatsapp: <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                 <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"/>
               </svg>
    };
    return icons[platform] || null;
  };

  return (
    <div className="space-y-4">
      <div className="text-center space-y-2">
        <p className="text-sm text-gray-600">Share with friends:</p>
        <div className="flex justify-center space-x-4">
          {Object.entries(shareLinks).map(([platform, url]) => (
            <a
              key={platform}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className={`p-2 rounded-full transition-colors ${getSocialButtonStyle(platform)}`}
            >
              {getSocialIcon(platform)}
            </a>
          ))}
          <button
            onClick={copyToClipboard}
            className="p-2 text-gray-600 hover:bg-gray-50 rounded-full transition-colors"
            title="Copy link"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

const VerifyEmail = () => {
  const { token } = useParams();
  const [status, setStatus] = useState('verifying');
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await waitlistApi.verifyEmail(token);
        setStatus('verified');
        setData(response);
      } catch (err) {
        setStatus('error');
        setError(err.message);
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <div className="min-h-screen bg-black">
      <nav className="absolute top-0 left-0 right-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
          <DirectiveLogo className="text-white" size={40} />
        </div>
      </nav>

      <div className="relative min-h-screen">
        <div className="absolute inset-0 overflow-hidden">
          <video
            className="w-full h-full object-cover"
            autoPlay
            loop
            muted
            playsInline
            src="/assets/videos/background.mp4"
          >
            Your browser does not support the video tag.
          </video>
          <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        </div>

        <div className="relative z-10 flex flex-col min-h-screen">
          <div className="flex-1 flex items-center justify-center px-4">
            <div className="bg-white rounded-lg p-8 max-w-md w-full">
              {status === 'verifying' && (
                <div className="text-center">
                  <h2 className="text-2xl font-bold mb-4">Verifying your email...</h2>
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black mx-auto"></div>
                </div>
              )}

              {status === 'verified' && data && (
                <div className="text-center">
                  <div className="mb-6">
                    <h3 className="text-xl font-bold text-green-600 mb-2">
                      Welcome to DirectiveAI!
                    </h3>
                    <p className="text-gray-600">
                      Thank you for joining! Share your referral code with friends.
                    </p>
                  </div>

                  <div className="bg-gray-50 p-6 rounded-lg mb-6 space-y-4">
                    <div className="space-y-2">
                      <p className="text-sm text-gray-600">
                        The human with the most invitations wins a cash prize.
                      </p>
                    </div>

                    <div className="bg-white p-4 rounded-md border border-gray-200">
                      <p className="text-sm text-gray-600 mb-2">Your Referral Code:</p>
                      <div className="flex items-center justify-center space-x-2">
                        <code className="font-mono text-lg bg-gray-50 px-4 py-2 rounded border">
                          {data?.referralCode}
                        </code>
                      </div>
                    </div>

                    <ShareSection referralCode={data?.referralCode} />
                  </div>

                  <button
                    onClick={() => window.location.href = '/'}
                    className="w-full bg-gray-100 text-gray-700 py-2 rounded-md hover:bg-gray-200"
                  >
                    Return to Home
                  </button>
                </div>
              )}

              {status === 'error' && (
                <div className="text-center">
                  <div className="mb-6">
                    <h3 className="text-xl font-bold text-red-600 mb-2">
                      Verification Failed
                    </h3>
                    <p className="text-gray-600 mb-4">{error}</p>
                  </div>

                  <button
                    onClick={() => window.location.href = '/'}
                    className="w-full bg-gray-100 text-gray-700 py-2 rounded-md hover:bg-gray-200"
                  >
                    Return to Home
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="pb-8">
            <div className="mt-4 text-white/80 text-sm text-center">
              powered by DirectiveAI Inc. 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;