import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Medal } from 'lucide-react';
import Button from '../components/ui/button';
import Portal from '../components/ui/portal';
import { marketApi } from '../utils/marketApi';

const LeaderboardRow = ({ user, rank }) => {
  const getMedalColor = (rank) => {
    switch (rank) {
      case 1: return "text-yellow-400";
      case 2: return "text-gray-400";
      case 3: return "text-amber-600";
      default: return "text-gray-300";
    }
  };

  return (
    <div className="flex items-center p-4 hover:bg-gray-50 border-b border-gray-100 transition-colors">
      <div className="w-12 flex justify-center">
        {rank <= 3 ? (
          <Medal className={`w-6 h-6 ${getMedalColor(rank)}`} />
        ) : (
          <span className="text-gray-500 font-medium">{rank}</span>
        )}
      </div>
      <div className="flex-1 ml-4">
        <div className="font-medium text-gray-900">
          {user.email.split('@')[0]}...
        </div>
        <div className="text-sm text-gray-500">
          {user.referralCount} referrals
        </div>
      </div>
      <div className="text-right">
        <div className="font-medium text-gray-900">
          ${user.contribution.toFixed(2)}
        </div>
        <div className="text-sm text-gray-500">
          {user.percentage.toFixed(2)}%
        </div>
      </div>
    </div>
  );
};

const Leaderboard = ({ data = [] }) => {
  const [sortedData, setSortedData] = useState([]);
  const [sortKey, setSortKey] = useState('contribution');
  const [sortDirection, setSortDirection] = useState('desc');

  useEffect(() => {
    const sorted = [...data].sort((a, b) => {
      const modifier = sortDirection === 'asc' ? 1 : -1;
      return (a[sortKey] - b[sortKey]) * modifier;
    });
    setSortedData(sorted);
  }, [data, sortKey, sortDirection]);

  const handleSort = (key) => {
    if (sortKey === key) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('desc');
    }
  };

  return (
    <div className="bg-white rounded-xl overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-2xl font-bold">Top Contributors</h2>
        <p className="text-gray-500 mt-1">Rankings based on referral performance</p>
      </div>
      
      <div className="flex p-4 bg-gray-50 border-b border-gray-200">
        <div className="w-12 text-center text-sm font-medium text-gray-500">Rank</div>
        <div className="flex-1 ml-4">
          <button 
            onClick={() => handleSort('referralCount')}
            className="text-sm font-medium text-gray-500 hover:text-gray-700"
          >
            User & Referrals
          </button>
        </div>
        <div className="text-right">
          <button 
            onClick={() => handleSort('contribution')}
            className="text-sm font-medium text-gray-500 hover:text-gray-700"
          >
            Contribution & Share
          </button>
        </div>
      </div>

      <div className="max-h-[600px] overflow-y-auto">
        {sortedData.map((user, index) => (
          <LeaderboardRow 
            key={user.email} 
            user={user} 
            rank={index + 1}
          />
        ))}
        
        {sortedData.length === 0 && (
          <div className="p-8 text-center text-gray-500">
            No contributors yet. Be the first one!
          </div>
        )}
      </div>
    </div>
  );
};

const AnimatedNumber = ({ value, prefix = '', suffix = '', duration = 1000, decimals = 2 }) => {
  const [displayValue, setDisplayValue] = useState(value);
  const steps = 30;

  useEffect(() => {
    let startValue = displayValue;
    const endValue = value;
    const increment = (endValue - startValue) / steps;
    const stepDuration = duration / steps;
    let currentStep = 0;

    const timer = setInterval(() => {
      if (currentStep < steps) {
        setDisplayValue(prevValue => {
          const newValue = prevValue + increment;
          return newValue + (Math.random() - 0.5) * Math.abs(increment);
        });
        currentStep++;
      } else {
        setDisplayValue(endValue);
        clearInterval(timer);
      }
    }, stepDuration);

    return () => clearInterval(timer);
  }, [value, duration]);

  return (
    <span className="transition-colors">
      {prefix}{displayValue.toFixed(decimals)}{suffix}
    </span>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-3 rounded shadow border">
        <p className="text-sm text-gray-600">
          {new Date(label).toLocaleString()}
        </p>
        <p className="font-semibold text-blue-600">
          Price: ${Number(payload[0].value).toFixed(2)}
        </p>
      </div>
    );
  }
  return null;
};

const AnimatedChart = ({ data, isLoading }) => {
  const [visibleData, setVisibleData] = useState([]);
  
  useEffect(() => {
    if (isLoading) {
      setVisibleData([]);
      let progress = 0;
      const interval = setInterval(() => {
        progress += 0.05;
        if (progress >= 1) {
          setVisibleData(data);
          clearInterval(interval);
        } else {
          setVisibleData(data.slice(0, Math.floor(data.length * progress)));
        }
      }, 30);
      return () => clearInterval(interval);
    } else {
      setVisibleData(data);
    }
  }, [isLoading, data]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={visibleData} margin={{ top: 10, right: 30, left: 10, bottom: 10 }}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis 
          dataKey="timestamp" 
          type="number"
          domain={['auto', 'auto']}
          tickFormatter={(timestamp) => {
            const date = new Date(timestamp);
            return `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
          }}
        />
        <YAxis 
          domain={[
            dataMin => Math.max(0, Math.floor(dataMin * 0.95)),
            dataMax => Math.ceil(dataMax * 1.05)
          ]}
          tickFormatter={(value) => `$${value.toFixed(2)}`}
        />
        <Tooltip content={<CustomTooltip />} />
        <Line 
          type="monotone" 
          dataKey="price" 
          stroke="#2563eb"
          strokeWidth={2}
          dot={false}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const TutorialStep = ({ 
  children, 
  target, 
  position = 'bottom',
  onNext,
  onPrev,
  onFinish,
  isFirst,
  isLast 
}) => {
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [elementRect, setElementRect] = useState(null);
  const padding = 8;
  const borderRadius = 16;

  useEffect(() => {
    if (target) {
      const element = document.querySelector(target);
      if (element) {
        const rect = element.getBoundingClientRect();
        setElementRect(rect);
        
        const positions = {
          top: { top: rect.top - 80, left: rect.left + rect.width / 2 },
          bottom: { top: rect.bottom + 20, left: rect.left + rect.width / 2 },
          left: { top: rect.top + rect.height / 2, left: rect.left - 20 },
          right: { top: rect.top + rect.height / 2, left: rect.right + 20 }
        };
        
        setTooltipPosition(positions[position]);
      }
    }
  }, [target, position]);

  return (
    <>
      {elementRect && (
        <div 
          className="fixed border-2 border-blue-600 pointer-events-none z-50"
          style={{
            top: elementRect.top - padding,
            left: elementRect.left - padding,
            width: elementRect.width + padding * 2,
            height: elementRect.height + padding * 2,
            borderRadius: borderRadius
          }}
        />
      )}
      
      <div
        className="fixed z-50 bg-white rounded-xl shadow-xl p-4 w-80 transform -translate-x-1/2"
        style={{
          top: tooltipPosition.top,
          left: tooltipPosition.left
        }}
      >
        <div className="text-sm text-gray-700 mb-4">
          {children}
        </div>
        <div className="flex justify-between">
          {!isFirst && (
            <Button
              variant="outline"
              size="sm"
              onClick={onPrev}
            >
              Previous
            </Button>
          )}
          {isLast ? (
            <Button
              size="sm"
              onClick={onFinish}
              className="ml-auto"
            >
              Finish
            </Button>
          ) : (
            <Button
              size="sm"
              onClick={onNext}
              className="ml-auto"
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

const MarketTutorial = ({ onActiveElementChange }) => {
  const [isActive, setIsActive] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    {
      target: '[data-tutorial="current-price"]',
      elementId: 'current-price',
      content: "This is the current price of each referral. The price increases as more users join and generate referrals.",
      position: 'bottom'
    },
    {
      target: '[data-tutorial="pool-value"]',
      elementId: 'pool-value',
      content: "The total pool value represents all referrals combined. The more referrals you generate, the bigger your share of the pool!",
      position: 'bottom'
    },
    {
      target: '[data-tutorial="participants"]',
      elementId: 'participants',
      content: "Keep track of your competition! This shows how many users are participating in the referral program.",
      position: 'bottom'
    },
    {
      target: '[data-tutorial="price-chart"]',
      elementId: 'price-chart',
      content: "Watch the price history to identify trends and the best moments to share your referral code!",
      position: 'top'
    }
  ];

  useEffect(() => {
    if (isActive) {
      onActiveElementChange(steps[currentStep].elementId);
    } else {
      onActiveElementChange(null);
    }
  }, [isActive, currentStep, onActiveElementChange]);

  const handleStart = () => {
    setIsActive(true);
    setCurrentStep(0);
  };

  const handleNext = () => {
    setCurrentStep(prev => Math.min(prev + 1, steps.length - 1));
  };

  const handlePrev = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0));
  };

  const handleFinish = () => {
    setIsActive(false);
    setCurrentStep(0);
  };

  return (
    <>
      <Button
        onClick={handleStart}
        className="fixed bottom-4 right-4 z-40"
        size="lg"
      >
        <svg 
          className="w-5 h-5 mr-2" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        How it works
      </Button>

      {isActive && (
        <Portal>
          <TutorialStep
            target={steps[currentStep].target}
            position={steps[currentStep].position}
            onNext={handleNext}
            onPrev={handlePrev}
            onFinish={handleFinish}
            isFirst={currentStep === 0}
            isLast={currentStep === steps.length - 1}
          >
            {steps[currentStep].content}
          </TutorialStep>
        </Portal>
      )}
    </>
  );
};

const Market = () => {
  const [marketState, setMarketState] = useState({
    currentPrice: 0,
    priceChange: 0,
    poolValue: 0,
    totalParticipants: 0
  });
  const [priceHistory, setPriceHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTutorialElement, setActiveTutorialElement] = useState(null);
  const [leaderboard, setLeaderboard] = useState([]);

  const getTutorialStyle = (elementId) => {
    if (!activeTutorialElement) return '';
    return elementId === activeTutorialElement 
      ? 'opacity-100' 
      : 'opacity-0 transition-all duration-300';
  };

  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        setLoading(true);
        
        const [state, historyData, board] = await Promise.all([
          marketApi.getCurrentState(),
          marketApi.getPriceHistory(),
          marketApi.getLeaderboard()
        ]);

        setMarketState({
            currentPrice: Number(state.currentPrice || 0),
            priceChange: Number(state.priceChange || 0),
            poolValue: Number(state.poolValue || 0),
            totalParticipants: board.leaderboard.length || 0
          });
  
          setLeaderboard(board.leaderboard || []);
  
          if (historyData && Array.isArray(historyData.prices)) {
            const processedHistory = historyData.prices
              .map(item => ({
                timestamp: Number(item.timestamp),
                price: Number(item.price),
                poolValue: Number(item.poolValue || 0)
              }))
              .filter(item => !isNaN(item.timestamp) && !isNaN(item.price))
              .sort((a, b) => a.timestamp - b.timestamp);
  
            setPriceHistory(processedHistory);
          }
  
        } catch (err) {
          setError(err.message);
        } finally {
          setTimeout(() => setLoading(false), 1000);
        }
      };
  
      fetchMarketData();
      const interval = setInterval(fetchMarketData, 60000);
      return () => clearInterval(interval);
    }, []);
  
    if (error) {
      return (
        <div className="min-h-screen bg-black/95 flex items-center justify-center">
          <div className="text-red-500">Error: {error}</div>
        </div>
      );
    }
  
    return (
      <div className="min-h-screen p-6 relative">
        <div 
          className="fixed inset-0 z-0"
          style={{
            backgroundImage: 'url("/assets/images/frame_0.png")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            filter: 'brightness(0.5)'
          }}
        />
        <div className="relative z-10">
          <div className="max-w-7xl mx-auto">
            <div className="bg-white rounded-xl p-6 mb-6">
              <h2 className="text-2xl font-bold mb-4">Market Overview</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div 
                  className={`bg-white p-4 rounded-lg transition-all duration-300 ${getTutorialStyle('current-price')}`}
                  data-tutorial="current-price"
                >
                  <div className="text-sm text-gray-500">Current Price</div>
                  <div className="text-2xl font-bold">
                    <AnimatedNumber value={marketState.currentPrice} prefix="$" />
                  </div>
                  <div className={`text-sm ${marketState.priceChange >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                    {marketState.priceChange >= 0 ? '↑' : '↓'} 
                    <AnimatedNumber value={Math.abs(marketState.priceChange)} suffix="%" />
                  </div>
                </div>
                <div 
                  className={`bg-white p-4 rounded-lg transition-all duration-300 ${getTutorialStyle('pool-value')}`}
                  data-tutorial="pool-value"
                >
                  <div className="text-sm text-gray-500">Total Pool Value</div>
                  <div className="text-2xl font-bold">
                    <AnimatedNumber value={marketState.poolValue} prefix="$" />
                  </div>
                </div>
                <div 
                  className={`bg-white p-4 rounded-lg transition-all duration-300 ${getTutorialStyle('participants')}`}
                  data-tutorial="participants"
                >
                  <div className="text-sm text-gray-500">Total Participants</div>
                  <div className="text-2xl font-bold">
                    <AnimatedNumber value={marketState.totalParticipants} decimals={0} />
                  </div>
                </div>
              </div>
            </div>
  
            <div className="bg-white rounded-xl p-6 mb-6">
              <h2 className="text-2xl font-bold mb-4">Price History</h2>
              <div 
                className={`h-96 transition-all duration-300 ${getTutorialStyle('price-chart')}`}
                data-tutorial="price-chart"
              >
                {priceHistory.length > 0 ? (
                  <AnimatedChart data={priceHistory} isLoading={loading} />
                ) : (
                  <div className="h-full flex items-center justify-center text-gray-500">
                    No price history available
                  </div>
                )}
              </div>
            </div>
  
            <div className="rounded-xl">
              <Leaderboard data={leaderboard} />
            </div>
          </div>
        </div>
        <MarketTutorial onActiveElementChange={setActiveTutorialElement} />
      </div>
    );
  };
  
  export default Market;